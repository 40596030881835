.hero-btns {
    display: flex;
    flex-direction: row; /* Standard: Buttons nebeneinander */
    justify-content: center;
    align-items: center;
    gap: 1rem; /* Abstand zwischen den Buttons */
    margin-top: 32px;
  }
  
  /* Buttons anpassen */
  .hero-btns .btn {
    min-width: 220px;
    max-width: 300px;
    width: auto;
    text-align: center;
  }
  
  /* Media Query für kleinere Bildschirme */
  @media screen and (max-width: 768px) {
    .hero-btns {
      flex-direction: column; /* Buttons untereinander */
    }
  
    .hero-btns .btn {
      min-width: 180px;
    }
  }
  